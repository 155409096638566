<template>
  <div>
    <condition-part
      :hasDate="true"
      :hasTradeStatusType="true"
      :hasStore="true"
      :hasTerminal="true"
      :hasCustomer="true"
      :hasEmployee="true"
      :hasExcel="true"
      :hasPdf="true"
      :hasAdd="false"
      :hasEdit="false"
      :hasRemove="false"
      :columns="columns"
      :items="items"
      :pagination="pagination"
      :isSortable="true"
      @getItems="getItems"
      @downloadExcel="downloadExcel"
      @downloadPdf="downloadPdf"
      @printPdf="printPdf"
      :reportTypes="reportTypes"
    >
      <template
        slot="dataButtons"
        slot-scope="props"
        v-if="reportType == 'simple'"
      >
        <button
          class="btn btn-outline-primary btn-icon"
          type="button"
          @click="onDetailClick(props.index)"
        >
          <i class="fas fa-file-invoice-dollar"></i>
        </button>
      </template>
    </condition-part>

    <salData :trade="trade" :isShow="isShow" @onClosed="dataOnClosed"></salData>
  </div>
</template>

<script>
import conditionPart from "@/components/parts/conditionPart";
import salData from "./SalData";

export default {
  data() {
    return {
      items: [],
      pagination: {},
      reportTypes: ["simple", "detail", "statistic"],
      reportType: "simple",
      trade: {},
      isShow: false,
    };
  },
  computed: {
    columns() {
      if (this.reportType == "detail") {
        return [
          { name: "CustomerNumber" },
          { name: "CustomerName" },
          { name: "TerminalNumber" },
          { name: "TerminalName" },
          { name: "BusinessDay" },
          { name: "SalNumber" },
          { name: "TradeType" },
          { name: "SalTime" },
          { name: "EmployeeNumber" },
          { name: "EmployeeName" },
          { name: "SalerNumber" },
          { name: "SalerName" },
          { name: "SalTotal" },
          { name: "TotalDiscountPrice" },
          { name: "TotalFreePrice" },
          { name: "FoodNumber" },
          { name: "FoodName" },
          { name: "Qty" },
          { name: "SalPrice" },
          { name: "Total" },
          { name: "Unit" },
        ];
      } else if (this.reportType == "simple") {
        return [
          { name: "CustomerNumber" },
          { name: "CustomerName" },
          { name: "TerminalNumber" },
          { name: "TerminalName" },
          { name: "BusinessDay" },
          { name: "SalNumber" },
          { name: "TradeType" },
          { name: "InvoiceNumber" },
          { name: "EmployeeName" },
          { name: "SalerName" },
          { name: "Total" },
          { name: "TotalDiscountPrice" },
          { name: "TotalFreePrice" },
        ];
      } else if (this.reportType == "statistic") {
        return [
          { name: "CustomerNumber" },
          { name: "CustomerName" },
          { name: "Total" },
          { name: "SaleTotal" },
          { name: "SaleReturnTotal" },
          { name: "YTaxPrice" },
          { name: "TaxPrice" },
          { name: "NTaxPrice" },
          { name: "ZTaxPrice" },
          { name: "TotalDiscountPrice" },
        ];
      } else return undefined;
    },
  },
  components: {
    conditionPart,
    salData,
  },
  methods: {
    downloadExcel(condition) {
      this.$api.reportExcels.getCustomer(condition);
    },
    downloadPdf(condition) {
      this.$api.reportPdfs.getCustomer(condition);
    },
    printPdf(condition) {
      this.$api.reportPdfs.printCustomer(condition);
    },
    getItems(condition) {
      this.reportType = condition.ReportType;

      const vm = this;
      this.$api.reports.getCustomer(condition).then((data) => {
        vm.items = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
    onDetailClick(index) {
      const vm = this;
      const sal = this.items[index];
      if (sal) {
        this.$api.sals
          .get(sal.SalId)
          .then((data) => {
            vm.trade = data;
            vm.isShow = !vm.isShow;
          })
          .catch(() => vm.$func.message.alert("查無此資料 !"));
      } else {
        this.$func.message.alert("請選擇一筆銷貨單 !");
      }
    },
    dataOnClosed() {
      this.isShow = !this.isShow;
    },
  },
};
</script>
