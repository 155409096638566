<template>
  <div>
    <pageTable
      :parentPage="pagination"
      @getItems="getModifierGroups"
      @addItem="addModifierGroup"
      @editItem="editModifierGroup"
      @removeItem="removeModifierGroup"
      :items="modifierGroups"
      :columns="columns"
      :idProperty="idProperty"
      :nameProperty="nameProperty"
      :getTrigger="getTrigger"
      :hasAdd="authority.IsAdd"
      :hasEdit="authority.IsUpdate"
      :hasRemove="authority.IsRemove"
      @currentIndexChanged="currentIndexChanged"
      :tableName="componentId"
    >
      <template slot="add">
        <button
          type="button"
          class="btn btn-outline-primary btn-icon dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span class="pr-2">
            <i class="fa fa-plus"></i>
          </span>
        </button>
        <div class="dropdown-menu">
          <a class="dropdown-item" @click="addModifierGroup">新建</a>
          <a
            class="dropdown-item"
            @click="tasteKindSelectTrigger = !tasteKindSelectTrigger"
            >選擇</a
          >
        </div>
      </template>
      <template slot="button">
        <button
          type="button"
          class="btn btn-outline-info py-2 px-4"
          style="border-color: transparent"
          @click="showItems"
        >
          <i class="fas fa-hamburger"></i>
        </button>
      </template>
    </pageTable>

    <modifier-group-data
      :isShow="isShowModal"
      :entity="modifierGroup"
      :onlineOrderType="onlineOrderType"
      @save="saveModifierGroup"
      @close="isShowModal = !isShowModal"
    ></modifier-group-data>

    <taste-kind-select
      :trigger="tasteKindSelectTrigger"
      :isMultiple="true"
      @onSaved="addMultipleTasteKind"
    >
    </taste-kind-select>

    <online-order-item-select
      :trigger="itemTrigger"
      :isMultiple="true"
      :defaultItems="onlineOrderItems"
      :onlineOrderType="onlineOrderType"
      @onSaved="saveItems"
    ></online-order-item-select>
  </div>
</template>

<script>
import pageTable from "@/components/PageTable";
import modifierGroupData from "@/components/onlineOrders/OnlineOrderModifierGroupData";
import tasteKindSelect from "@/components/selects/TasteKindSelects";
import onlineOrderItemSelect from "@/components/selects/OnlineOrderItemSelects";

export default {
  data() {
    return {
      modifierGroups: [],
      modifierGroup: {},
      pagination: {},
      isShowModal: false,
      columns: [
        {
          name: "OnlineOrderModifierGroupName",
        },
        {
          name: "MinQty",
        },
        {
          name: "MaxQty",
        },
        {
          name: "OnlineOrderModifierText",
          headerText: "口味",
        },
      ],
      idProperty: "OnlineOrderModifierGroupId",
      nameProperty: "OnlineOrderModifierGroupName",
      getTrigger: false,
      authority: {},
      tasteKindSelectTrigger: false,
      itemTrigger: false,
      onlineOrderItems: undefined,
      currentIndex: -1,
    };
  },
  props: ["componentId", "onlineOrderType"],
  components: {
    pageTable,
    modifierGroupData,
    tasteKindSelect,
    onlineOrderItemSelect,
  },
  methods: {
    currentIndexChanged(index) {
      this.currentIndex = index;
    },
    getModifierGroups(dTO) {
      dTO.type = this.onlineOrderType;
      const vm = this;
      this.$api.onlineOrderModifierGroups.getPages(dTO).then((data) => {
        vm.modifierGroups = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
    addModifierGroup() {
      this.modifierGroup = this.$func.defaultModel.getOnlineOrderModifierGroup(
        this.onlineOrderType
      );
      this.isShowModal = !this.isShowModal;
    },
    addMultipleTasteKind(items) {
      this.$func.message.confirm("是否一併加入口味細項?").then((result) => {
        const vm = this;
        this.$api.onlineOrderModifierGroups
          .postBatch({
            OnlineOrderType: this.onlineOrderType,
            TasteKindIds: items.map((x) => x.TasteKindId),
            IsAddDetail: result.isConfirmed,
          })
          .then(() => {
            vm.getTrigger = !vm.getTrigger;
          });
      });
    },
    editModifierGroup(modifierGroup) {
      const vm = this;
      this.$api.onlineOrderModifierGroups
        .get(modifierGroup.OnlineOrderModifierGroupId)
        .then((data) => {
          vm.modifierGroup = data;
          vm.isShowModal = !vm.isShowModal;
        });
    },
    removeModifierGroup(modifierGroup) {
      const vm = this;
      this.$api.onlineOrderModifierGroups
        .remove(modifierGroup.OnlineOrderModifierGroupId)
        .then(() => {
          vm.getTrigger = !vm.getTrigger;
        });
    },
    saveModifierGroup() {
      this.isShowModal = !this.isShowModal;
      this.getTrigger = !this.getTrigger;
    },
    showItems() {
      const modifierGroup = this.modifierGroups[this.currentIndex];
      if (!modifierGroup) {
        this.$func.message.alert("請選擇口味 !");
        return;
      }
      this.onlineOrderItems = undefined;
      const vm = this;
      this.$api.onlineOrderModifierGroups
        .getItems(modifierGroup.OnlineOrderModifierGroupId)
        .then((data) => {
          vm.onlineOrderItems = data;
          vm.itemTrigger = !vm.itemTrigger;
        });
    },
    saveItems(items) {
      const modifierGroup = this.modifierGroups[this.currentIndex];
      if (!modifierGroup) {
        this.$func.message.alert("請選擇口味 !");
        return;
      }
      const vm = this;
      this.$api.onlineOrderModifierGroups
        .postItems(
          modifierGroup.OnlineOrderModifierGroupId,
          items.map((item) => item.OnlineOrderItemId)
        )
        .then(() => {
          vm.getTrigger = !vm.getTrigger;
        })
        .catch((error) => {
          vm.$func.message.error(error.data.Message);
        });
    },
  },
  created() {
    this.authority = this.$func.localStorage.getAuthByName(this.$route.name);
  },
};
</script>
