<template>
  <div>
    <div class="card card-table">
      <div
        class="card-header lineorder-menu-title col-12 d-flex align-items-center"
      >
        <h5>選單</h5>
      </div>
      <div class="card-body overflow-hidden">
        <div class="row">
          <div class="col-12 d-flex mt-3">
            <label
              for="lineOrderImageColorType"
              class="col-4 col-sm-3 col-lg-2 col-xl-1 col-form-label"
              >主題</label
            >
            <select
              id="lineOrderImageColorType"
              class="form-control"
              v-model="lineImage.LineOrderImageColorType"
              @change="save"
            >
              <option
                v-for="type in lineOrderImageColorTypes"
                :key="type.name"
                :value="type.value"
              >
                {{ type.text }}
              </option>
            </select>
          </div>
          <div class="col-12 d-flex mt-3">
            <label
              for="lineMessageToken"
              class="col-4 col-sm-3 col-lg-2 col-xl-1 col-form-label"
              >格式</label
            >
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="oneButton"
                value="0"
                @change="save"
                v-model="lineImage.LineOrderImageShapeType"
              />
              <label class="form-check-label" for="oneButton">一個按鍵</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="fourButton"
                value="1"
                @change="save"
                v-model="lineImage.LineOrderImageShapeType"
              />
              <label class="form-check-label" for="fourButton">四個按鍵</label>
            </div>
          </div>
          <div class="col-12 d-flex align-items-center my-3">
            <label
              for="lineOrderImage"
              class="col-4 col-sm-3 col-lg-2 col-xl-1 col-form-label"
              >圖片</label
            >
            <div>
              <img class="img-order-menu" :src="img" alt="" v-if="img" />
              <div
                v-else
                class="img-order-menu border-gray"
                style="width: 237px"
              ></div>
            </div>

            <div class="col-auto d-flex align-items-end h-100 mb-0">
              <button
                class="btn btn-outline-primary"
                type="button"
                @click="downloadImage"
              >
                <i class="fas fa-download"></i>
              </button>
            </div>
          </div>
          <div
            class="lineorder-link-title col-12 d-flex align-items-center my-3 w-100"
          >
            <h5>連結</h5>
          </div>
          <div class="col-12 d-flex my-3">
            <label
              for="menu"
              class="col-3 col-sm-3 col-lg-2 col-xl-1 col-form-label"
              >立刻點餐</label
            >
            <input
              type="text"
              class="col-6 col-sm-6 col-lg-5 col-xl-4 form-control"
              id="menu"
              v-model="lineImage.LineOrderURL"
              disabled
            />
            <div class="col-auto">
              <button
                class="btn btn-outline-primary"
                type="button"
                @click="copyUrl('menu')"
              >
                <i class="fas fa-copy"></i>
              </button>
            </div>
          </div>
          <div v-if="isFour" class="col-12 d-flex my-3">
            <label
              for="orderStatus"
              class="col-3 col-sm-3 col-lg-2 col-xl-1 col-form-label"
              >追蹤訂單</label
            >
            <input
              type="text"
              class="col-6 col-sm-6 col-lg-5 col-xl-4 form-control"
              id="orderStatus"
              v-model="lineImage.LineOrderQueryURL"
              disabled
            />
            <div class="col-auto">
              <button
                class="btn btn-outline-primary"
                type="button"
                @click="copyUrl('orderStatus')"
              >
                <i class="fas fa-copy"></i>
              </button>
            </div>
          </div>
          <div v-if="isFour" class="col-12 d-flex my-3">
            <label
              for="member"
              class="col-3 col-sm-3 col-lg-2 col-xl-1 col-form-label"
              >個人資料</label
            >
            <input
              type="text"
              class="col-6 col-sm-6 col-lg-5 col-xl-4 form-control"
              id="member"
              v-model="lineImage.LineOrderMemberURL"
              disabled
            />
            <div class="col-auto">
              <button
                class="btn btn-outline-primary"
                type="button"
                @click="copyUrl('member')"
              >
                <i class="fas fa-copy"></i>
              </button>
            </div>
          </div>
          <div v-if="isFour" class="col-12 d-flex my-3">
            <label
              for="historyOrders"
              class="col-3 col-sm-3 col-lg-2 col-xl-1 col-form-label"
              >歷史訂單</label
            >
            <input
              type="text"
              class="col-6 col-sm-6 col-lg-5 col-xl-4 form-control"
              id="historyOrders"
              v-model="lineImage.LineOrderHistoryURL"
              disabled
            />
            <div class="col-auto">
              <button
                class="btn btn-outline-primary"
                type="button"
                @click="copyUrl('historyOrders')"
              >
                <i class="fas fa-copy"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lineOrderImageColorTypes: this.$enum.lineOrderImageColorType.getAll(),
      lineImage: {},
      img: "",
      file: "",
      isSave: true,
    };
  },
  computed: {
    isFour() {
      return this.lineImage.LineOrderImageShapeType == 1;
    },
  },
  methods: {
    get(id) {
      const vm = this;
      this.$api.line.getImage(id).then((data) => {
        vm.lineImage = data;
        vm.img = `data:image/jpg;base64,${data.LineOrderImage}`;
      });
    },
    save() {
      const vm = this;
      this.lineImage.LineOrderImageShapeType = parseInt(
        this.lineImage.LineOrderImageShapeType
      );
      let postData = {
        DeviceId: this.$route.params.id,
        LineOrderImageColorType: this.lineImage.LineOrderImageColorType,
        LineOrderImageShapeType: parseInt(
          this.lineImage.LineOrderImageShapeType
        ),
      };
      this.$api.line
        .saveImage(postData)
        .then(() => {
          vm.$func.message.success(vm.$t("funcMsg.updatesuccess"));
          vm.get(vm.$route.params.id);
        })
        .catch((error) => {
          vm.$func.message.error(error);
        });
    },
    downloadImage() {
      var a = document.createElement("a");
      a.href = this.img;
      a.download = "image.jpg";
      a.click();
    },
    copyUrl(urlId) {
      const url = document.getElementById(`${urlId}`).value;
      navigator.clipboard.writeText(url);
      this.$func.message.success("網址複製成功 !");
    },
  },
  created() {
    this.get(this.$route.params.id);
  },
};
</script>
