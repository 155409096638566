import { httpGet, httpPost, httpDelete } from "@/request/http";

let path = "/line";

export default {
  get(id) {
    return httpGet(`/devices/${id}${path}-setting`);
  },
  save(item) {
    return httpPost(`/devices${path}-setting`, item);
  },
  remove() {
    return httpDelete(`/devices${path}-image`);
  },
  getImage(id) {
    return httpGet(`/devices/${id}${path}-image`);
  },
  saveImage(item) {
    return httpPost(`/devices${path}-image`, item);
  },
};
