<template>
  <div>
    <search-part @getItems="getBrands">
      <template slot="buttonArea">
        <import-part
          @onImport="onImport"
          :title="`${$t('modal.importbrand')}`"
        />
        <export-part @onExport="onExport" />
      </template>
      <template slot="searchArea">
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group row">
              <label
                for="searchBrandNumber"
                class="col-3 col-md-2 col-lg-3 col-form-label"
                >編號:</label
              >
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  id="searchBrandNumber"
                  v-model="searchDTO.BrandNumber"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group row">
              <label
                for="searchBrandName"
                class="col-3 col-md-2 col-lg-3 col-form-label"
                >名稱:</label
              >
              <div class="col">
                <input
                  type="text"
                  class="form-control"
                  id="searchBrandName"
                  v-model="searchDTO.BrandName"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </search-part>
    <page-table
      :hasAdd="true"
      :hasEdit="true"
      :hasRemove="true"
      :parentPage="pagination"
      @getItems="getBrands"
      @addItem="addBrand"
      @editItem="editBrand"
      @removeItem="removeBrand"
      @saveItem="saveBrand"
      @trdbClicked="editBrand"
      :items="brands"
      :columns="columns"
      :idProperty="idProperty"
      :nameProperty="nameProperty"
      :getTrigger="getTrigger"
      :isSortable="true"
    >
      <template slot="button" slot-scope="props">
        <button
          type="button"
          class="btn btn-outline-primary btn-icon"
          @click="copy(props.index)"
        >
          <i class="fas fa-copy"></i></button></template
    ></page-table>

    <item-modal
      :isShowModal="isShowModal"
      @onClosed="isShowModal = !isShowModal"
      @onSaved="saveBrand"
      :title="modalTitle"
    >
      <div slot="item">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="brandNumber">{{ $t("modal.number") }}:</label>
              <input
                id="brandNumber"
                type="text"
                class="form-control"
                v-model="brand.BrandNumber"
                required
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="brandName">{{ $t("modal.name") }}:</label>
              <input
                type="text"
                class="form-control"
                id="brandName"
                v-model="brand.BrandName"
                required
              />
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label for="address">{{ $t("modal.address") }}:</label>
              <input
                type="text"
                class="form-control"
                id="address"
                v-model="brand.Address"
              />
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label for="memo">{{ $t("modal.memo") }}:</label>
              <input
                type="text"
                class="form-control"
                id="memo"
                v-model="brand.Memo"
              />
            </div>
          </div>
        </div>
      </div>
    </item-modal>
  </div>
</template>

<script>
import searchPart from "@/components/parts/searchPart";
import pageTable from "@/components/PageTable";
import itemModal from "@/components/ItemModal";

import importPart from "@/components/ImportPart";
import exportPart from "@/components/ExportPart";

export default {
  data() {
    return {
      brands: [],
      brand: {},
      columns: [
        {
          name: "BrandNumber",
        },
        {
          name: "BrandName",
        },
        {
          name: "Address",
        },
        {
          name: "Memo",
        },
      ],
      pagination: {},
      searchDTO: {},
      isShowModal: false,
      modalTitle: "",
      idProperty: "BrandId",
      nameProperty: "BrandName",
      getTrigger: false,
      elecInvoiceTrigger: false,
      brandId: "",
      authority: {},
    };
  },
  computed: {},
  watch: {},
  components: {
    searchPart,
    pageTable,
    itemModal,
    importPart,
    exportPart,
  },
  methods: {
    getBrands(dTO) {
      const vm = this;
      this.$api.brands.getPages({ ...dTO, ...this.searchDTO }).then((data) => {
        vm.brands = data.Datas;
        vm.pagination = data.Pagination;
      });
    },
    addBrand() {
      this.brand = {};
      this.isShowModal = !this.isShowModal;
      this.modalTitle = this.$t("modal.addbrand");
    },
    editBrand(item) {
      const vm = this;
      this.$api.brands.get(item.BrandId).then((data) => {
        vm.brand = data;
        vm.isShowModal = !vm.isShowModal;
        vm.modalTitle = this.$t("modal.editbrand");
      });
    },
    removeBrand(item) {
      const vm = this;
      this.$api.brands
        .remove(item.BrandId)
        .then(() => {
          this.$func.message.success("刪除成功 !");
          this.getTrigger = !this.getTrigger;
        })
        .catch((error) => vm.$func.message.error(error.data.Message));
    },
    saveBrand() {
      const vm = this;
      this.$api.brands
        .save(vm.brand)
        .then(() => {
          vm.isShowModal = !vm.isShowModal;
          vm.getTrigger = !vm.getTrigger;
        })
        .catch((error) => vm.$func.message.error(error.data.Message));
    },
    onImport(file) {
      let formData = new FormData();
      formData.append("file", file);
      const vm = this;
      this.$api.brands.postExcel(formData).then(() => {
        vm.getTrigger = !vm.getTrigger;
      });
    },
    onExport() {
      this.$api.brands.getExcel();
    },
    copy(index) {
      this.brand.BrandName = this.brands[index].BrandName;
      this.brand.Address = this.brands[index].Address;
      this.brand.Memo = this.brands[index].Memo;
      this.isShowModal = !this.isShowModal;
      this.modalTitle = this.$t("modal.addbrand");
    },
  },
  created() {
    this.authority = this.$func.localStorage.getAuthByName(this.$route.name);
  },
};
</script>
